<template>
<div>API</div>
</template>

<script>
import api from '@/utils/api'

export default {
  name: 'Doc',

  data () {
    return {
      loading : false
    }
  },

  async mounted () {
    this.getDataFromApi()
  },

  methods : {
    async getDataFromApi () {
      this.loading = true

      try {
        const r = await api.flowList({})
        console.table( r.data.flows)
        // console.info( r)
      } catch (e) {
        console.error('getDataFromApi', e)
      }
      this.switch1 = false
      this.loading = false
      return
    }
  }
}
</script>

<style scoped>

</style>